import Axios from "../../api/Axios";
import notifiesActions from "./notifies";
Axios.defaults.timeout = 15000;

const fetchCurrent = ({ localID }) => {
  return async (dispatch, getState) => {
    try {
      let response = await Axios.get("/webApp/removals", {
        params: { localID },
      });
      dispatch({ type: "REMOVALS_FETCH_CURRENT", payload: response.data });
    } catch (e) {
      console.log(e);
    }
  };
};

const fetchPrevius = ({ localID }) => {
  return async (dispatch, getState) => {
    try {
      let response = await Axios.get("/webApp/removals/previus", {
        params: { localID },
      });
      dispatch({ type: "REMOVALS_FETCH_PREVIUS", payload: response.data });
    } catch (e) {
      console.log(e);
    }
  };
};

const fetchHistoric = ({localID }) => {
  return async (dispatch, getState) => {
    try {
      let response = await Axios.get("/webApp/removals/historic", {
        params: { localID },
      });
      dispatch({ type: "REMOVALS_FETCH_HISTORIC", payload: response.data });
    } catch (e) {
      console.log(e);
    }
  };
};

const fetchHistoricTransporter = ({localID }) => {
  return async (dispatch, getState) => {
    try {
      let response = await Axios.get("/webApp/removals/historic/transporter", {
        params: { localID },
      });
      dispatch({ type: "REMOVALS_FETCH_HISTORIC", payload: response.data });
    } catch (e) {
      console.log(e);
    }
  };
};
const removalInactive = (id) => {
  return async (dispatch) => {
    try {
      let response = await Axios.get("/webApp/removals/inactive", {
        params: { _id: id },
      });
      dispatch(fetchCurrent());
      dispatch({ type: "REMOVALS_INACTIVE"});
    } catch (e) {
      console.log(e);
    }
  };
};

const setRating = (rating, removalID) => {
  return async (dispatch) => {
    try {
      await Axios.post("/webApp/removals/setRating", {
        rating,
        removalID,
      });
      // dispatch(fetchCurrent()); // causaba error y no es necesario hacer fetch si sólo se hace rating
      dispatch(notifiesActions.enqueueNotify("REMOVAL_SET_RATING_SUCCESS"));
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_SET_RATING_FAIL"));
    }
  };
};

const removalVehicleidNull = () => {
  
  return async (dispatch) => {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_VEHICLEID_NULL"));
    }
};

const removalMaterialsNull = () => {
  
  return async (dispatch) => {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_MATERIALS_NULL"));
    }
};

const requestRemovalBySensorState = (removal) => {
  return async (dispatch) => {
    try {
      let response= await Axios.put("/webApp/removalsSensorState", {
        materials: removal.materials,
        localID: removal.localID,
        datetimeRemoval : removal.datetimeRemoval,
        _id: removal._id,
      });
      if (response) {
        dispatch(fetchCurrent());
        dispatch({ type: "REMOVALS_CREATE_BY_SENSORS"});
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

};


const agree = (userID,removal) => {
     
  
  return async (dispatch) => {
    
    try {
      let response= await Axios.patch("/webApp/removals", {
        "userID": userID,
        "statusTransport" : "ACCEPTED",
        "_id": removal._id,
      });
      if (response) {
        dispatch(fetchCurrent());
        dispatch({ type: "REMOVALS_CREATE_BY_SENSORS"});
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };
};

const deny = (userID,removal) => {
     
  
  return async (dispatch) => {
    
    try {
      let response= await Axios.patch("/webApp/removals", {
        "userID": userID,
        "statusTransport" : "REJECTED",
        "_id": removal._id,
      });
      if (response) {
        dispatch(fetchCurrent());
        dispatch({ type: "REMOVALS_CREATE_BY_SENSORS"});
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };
};


const requestRemoval = ({ formData, userType, entityID }) => {
  return async (dispatch, getState) => {
    try {
      let localID = getState().removals.localID;
      const thisLocalID = userType === "LOCAL" ? entityID : localID;
      let response = await Axios.post("/webApp/removals", {
        ...formData,
        localID: thisLocalID,
      });
      if (response) {
        
        //await dispatch(fetchCurrent());
        dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_SUCCESS"));
        return true;
      } else {
        
        dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_FAIL"));
        return false;
      }
    } catch (e) {
      
      dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_FAIL"));
      return false;
    }
  };
};

const sendRemovalTran = (data) => {
  return async (dispatch) => {
    try {
      await Axios.patch("/webapp/removals", data);
      dispatch(notifiesActions.enqueueNotify("REMOVALS_SET_DATA_SUCCESS"));
      //dispatch(fetchCurrent());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REMOVALS_SET_DATA_FAIL"));
      return false;
    }
  };
};

const createRemovalExtra = ({ formData }) => {
  return async (dispatch) => {
    try {
      let response = await Axios.post("webApp/removalsExtra", {
        ...formData,
      });
      if (response) {
        dispatch(notifiesActions.enqueueNotify("REMOVAL_EXTRA_CREATE_SUCCESS"));
        dispatch(setLocalID({ localID: formData.localID }));
        // dispatch(fetchCurrent()); // causaba error y no es necesario hacer fetch 
      } else {
        dispatch(notifiesActions.enqueueNotify("REMOVAL_EXTRA_CREATE_FAIL"));
      }
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_EXTRA_CREATE_FAIL"));
    }
  };
};

const setLocalID = ({ localID }) => {
  return async (dispatch) => {
    dispatch({ type: "REMOVALS_SET_LOCALID", payload: { localID } });
  };
};

const deleteRemovalInProgress = ({removalID, localID}) => {
  return async (dispatch) => {
    try {
      await Axios.delete("/webApp/removals", { params: { removalID, localID } });
      
      //await dispatch(fetchCurrent());
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_SUCCESS"));
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_FAIL"));
    }
  }; 
}

const fetchTransporter = ({ transporterID,time }) => {
  return async (dispatch, getState) => {
    try {
      let response = await Axios.get("/webApp/removalsTransporter", { params: {transporterID,time }});
      dispatch({ type: "REMOVALS_FETCH_CURRENT", payload: response.data });
    } catch (e) {
      console.log(e);
    }
    
  };
};

const getDispositions = () => {
  return async (dispatch, getState) => {
    try {
      let response = await Axios.get("/webApp/getDispositions");
      dispatch({ type: "DISPOSITIONS", payload: response.data });
    } catch (e) {
      console.log(e);
    }
    
  };
};

export default {
  fetchCurrent,
  fetchPrevius,
  fetchHistoric,
  fetchHistoricTransporter,
  setRating,
  deny,
  agree,
  getDispositions,
  requestRemoval,
  setLocalID,
  createRemovalExtra,
  removalVehicleidNull,
  deleteRemovalInProgress,
  removalInactive,
  requestRemovalBySensorState,
  sendRemovalTran,
  removalMaterialsNull,
  fetchTransporter
};
