import React, { Fragment, useEffect } from "react";
import { TextField, Grid, Typography } from "@material-ui/core";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import materialsList from "../../../../resources/materials";
import { makeStyles } from "@material-ui/styles";

const list_materials = materialsList.materials;

const useStyles = makeStyles(({ breakpoints }) => ({
  card: {
    width: "100%",
    maxWidth: 500,
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    overflow: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 8,
    background:
      "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(50,129,41,1) 29%, rgba(106,255,0,1) 92%)",
    [breakpoints.up("sm")]: {
      textAlign: "left",
      flexDirection: "row-reverse",
    },
  },
  media: {
    flexShrink: 0,
    width: "20%",
    paddingTop: "15%",
    marginLeft: "auto",
    marginRight: "auto",
    [breakpoints.up("sm")]: {
      marginRight: "initial",
    },
  },
  overline: {
    lineHeight: 2,
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "0.7rem",
    opacity: 0.7,
  },
  
  radiogroup: {
    textAlign: "center",
    display: "block",
  },
}));


const TextFieldComponent = ({ disabled, value, onChange, name, label }) => {
  return (
    <TextField
      id="outlined-required"
      variant="outlined"
      margin="dense"
      disabled={disabled}
      value={value}
      name={name}
      label={label}
      onChange={onChange}
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
    />
  );
};

function Materials({ disabled, setFormData, formData, with_materials, setWith_materials, setNextStepAllowed }) {
  const classes = useStyles();
  const updateMaterials = (e) => {
    let temp = {
      ...formData.materials,
      [e.target.name]: e.target.value > 0 ? e.target.value : 0,
    };

    let aux = 0;
    Object.keys(temp).map((key) => {
      aux += temp[key] * 1;
    });

    setFormData({
      ...formData,
      materialsIcon: Boolean(aux),
      materials: temp,
    });
  };

  
  
  const handleWith_materials = (e) => {
    setWith_materials(e.currentTarget.value==1);
    setNextStepAllowed(true);
  };

  //useEffect(() => {
    if (with_materials!=null) {
      setNextStepAllowed(true);
    }else{
      setNextStepAllowed(false);
    }
  //}

  return (
    <Fragment>
       <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
        <Grid item xs={12}>
          <Typography variant="h6" align="center">Paso 2: Materiales</Typography>
          <br/>
        </Grid>
      <Grid item xs={12} sm={12}>
          <Typography variant="body2" align="center">¿Este conjunto de materiales se encontraba disponible? </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <RadioGroup row aria-label="position" name="position" defaultValue="top" className={classes.radiogroup}>
                <FormControlLabel value="Si" control={<input
                type="radio"
                value="1"
                disabled={disabled}
                checked={with_materials === true}
                onChange={handleWith_materials}
                
              />} label="Sí" />&nbsp;
                <FormControlLabel value="No" control={<input
                type="radio"
                value="0"
                disabled={disabled}
                checked={with_materials === false}
                onChange={handleWith_materials}
              />} label="No" />
          </RadioGroup>
          </Grid>
        </Grid>
      {with_materials && (
      <Grid container spacing={2}>
        {formData.materials &&
          Object.keys(list_materials).map((material) => (
            <Grid item xs={6} sm={2} key={"material_" + material}>
              <TextFieldComponent
                name={material.toUpperCase()}
                label={list_materials[material].name}
                onChange={updateMaterials}
                disabled={disabled}
                value={formData.materials[material.toUpperCase()]}
              />
            </Grid>
          ))}
      </Grid>
      )}
    </Fragment>
  );
}

export default Materials;
